<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="订单ID">
      <el-input v-model="selectModel.id" />
    </el-form-item>
    <el-form-item label="用户ID">
      <el-input v-model="selectModel.userId" />
    </el-form-item>
    <el-form-item label="卡ID">
      <el-input v-model="selectModel.cardId" />
    </el-form-item>
    <el-form-item label="学校">
      <el-select v-model="selectModel.collegeId" placeholder="请选择" clearable>
        <el-option v-for="item in colleges" :key="item.id" :value="item.id" :label="item.val" />
      </el-select>
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="selectModel.status" placeholder="请选择" clearable>
        <el-option v-for="(a,b) in $store.state.userStatus" :key="b" :value="b" :label="a" />
      </el-select>
    </el-form-item>
    <el-form-item label="创建时间">
      <el-date-picker
        v-model="selectDatetime"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期" />
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="100px" align="center" sortable="custom" />
    <el-table-column label="创建时间" prop="createTime" min-width="180px" />
    <el-table-column label="用户ID" prop="userId" min-width="100px" sortable="custom" />
    <el-table-column label="会员卡" prop="cardId" min-width="100px" sortable="custom" />
    <el-table-column label="学校" prop="collegeName" min-width="200px" sortable="custom" />
    <el-table-column label="支付方式" prop="method" min-width="100px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('payMethod', scope) }}
      </template>
    </el-table-column>
    <el-table-column label="类型" prop="status" min-width="100px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('userStatus', scope) }}
      </template>
    </el-table-column>
    <el-table-column label="状态" prop="type" min-width="100px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('userType', scope) }}
      </template>
    </el-table-column>
    <el-table-column label="支付金额" prop="amount" min-width="100px" />
    <el-table-column label="赠送金额" prop="rechargeTemplateParam.give" min-width="100px" />
    <!-- <el-table-column
      fixed="right"
      label="操作"
      align="center"
      width="80">
      <template #default="scope">
      </template>
    </el-table-column> -->
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';
import { format } from '../../utils/datetime';
import moment from 'moment';

export default {
  data() {
    return {
      selectDatetime: [],
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      colleges: []
    };
  },
  created() {
    this.$api.getCollegeMap().then(data => {
      this.colleges = data;
    });
    this.selectModel.collegeId = this.$route.query.collegeId;
    this.applyDay7();
    this.applySortSelect(this.defaultSort);
    this.queryTableData();
  },
  mounted() {
  },
  methods: {
    queryTableData() {
      this.applyDatetime();
      this.selectModel.sort = this.selectSort;
      this.selectModel.page = this.current - 1;
      this.selectModel.type = 'Recharge';
      this.$api.getUserOrderPage(this.selectModel)
        .then(data => {
          this.tableData = data.content;
          this.total = data.totalElements;
        })
        .catch(handleCatchStatus);
    },
    applyDatetime() {
      if (this.selectDatetime && this.selectDatetime.length === 2) {
        this.selectModel.startTime = format(this.selectDatetime[0]);
        this.selectModel.endTime = format(this.selectDatetime[1]);
      } else {
        this.selectModel.startTime = null;
        this.selectModel.endTime = null;
      }
    },
    applyDay7() {
      const now = moment().toDate();
      const day7 = moment().subtract(7, 'days').toDate();
      this.selectDatetime = [day7, now];
    },
    sort({ prop, order }) {
      if (prop === 'collegeName') {
        prop = 'collegeId';
      }
      this.applySortSelect({ prop, order });
      this.queryTableData();
    },
    applySortSelect({ prop, order }) {
      if (order) {
        this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
        return;
      }
      this.selectSort = null;
    },
    applyCurrentPage(current) {
      this.current = current;
      this.queryTableData();
    }
  }
};
</script>
